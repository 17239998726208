<template>
  <div class="navt">

    <!-- 当前位置 -->
    <div class="cont">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">当前位置: 首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="(item,index) in navData" :key='index' :to="{ path: item.url }">{{item.name}}</el-breadcrumb-item>

      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      navData:{
        type: Array,
        default:[],
      }
    },
    data() {
      return {
        navTitle: '',
      }
    },
    methods: {

    },
  }
</script>

<style lang="less" scoped>
  .navt {
    font-size: 12px;
    text-align: left;
    .cont {
      color: #000;
      padding: 30px 0 20px;
    }
  }
</style>
